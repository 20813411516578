import { Solution } from './../../models/solution/solution.model';
import { UserFilter } from './../../models/user/user-filter.model';
import { Customer, CustomerFilter, PageResult } from '../../models';
import { DatasourceFilter } from '../../models/datasource/datasources-filter.model';
import { User } from '../../models/user/user.model';
import _ from 'lodash';
import { UIConfig } from './config-service';
import { CCApiService } from './api-service';
import { ApiError } from '../../components/errors';

export class CustomerApiService {
  static ApiService: CCApiService;
  static customersAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customersAPI = `${this.apiBasePath}/customers`;
  }

  static async getCustomers(filter?: CustomerFilter): Promise<PageResult<Customer>> {
    const params = new URLSearchParams(filter);
    const url = params.toString() ? `${this.customersAPI}?${params}` : this.customersAPI;
    return this.ApiService.performFetch(url).then(customers => customers);
  }

  static async getCustomer(id: string) {
    return this.ApiService.performFetch(`${this.customersAPI}/${id}`).then(customer => customer);
  }

  static async createCustomer(data: Customer) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return this.ApiService.performFetch(this.customersAPI, fetchOptions).then(resp => resp);
  }

  static async editCustomer(id: string, data) {
    return this._updateCustomer(id, data, 'PUT');
  }

  static async patchCustomer(id: string, data) {
    return this._updateCustomer(id, data, 'PATCH');
  }

  private static async _updateCustomer(id: string, data, method: 'PUT' | 'PATCH') {
    const fetchOptions = {
      method,
      body: JSON.stringify(data),
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${id}`, fetchOptions).then(resp => resp);
  }

  static async deleteCustomer(id) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${id}`, fetchOptions);
  }

  static async getUsers(filter: UserFilter) {
    const queryString = this.getQueryString(_.omit(filter, ['customerId', 'solutionId']));
    const { customerId, solutionId } = filter;
    const url = solutionId
      ? `${this.customersAPI}/${customerId}/solutions/${solutionId}/users`
      : `${this.customersAPI}/${customerId}/users`;
    return this.ApiService.performFetch(url + queryString);
  }

  static async getUsersWithAppRoles(app: Solution): Promise<User[]> {
    return this.ApiService.performFetch(
      ` ${this.apiBasePath}/customers/${app.customer.id}/solutions/${app.id}/users`
    ).then(result => result);
  }

  static async getUsersWithoutAppRoles(app: Solution): Promise<User[]> {
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${app.customer.id}/solutions/${app.id}/users/unassigned`
    ).then(result => result);
  }

  static async getUser(customerId: string, user: User) {
    const url = `${this.customersAPI}/${customerId}/users/${user.email}`;
    return this.ApiService.performFetch(url);
  }

  static async getDatasources(filter: DatasourceFilter = {}) {
    const params = CustomerApiService.getQueryString(filter);
    const { customerId } = filter;
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/datasources${params}`);
  }

  static getQueryString = (filter: DatasourceFilter | UserFilter = {}) => {
    const params = new URLSearchParams(filter as any);
    if (params && params.toString()) {
      return `?${params}`;
    }
    return '';
  };

  static async addUser(customerId: string, user: User) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(user),
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/users`, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async patchUser(customerId: string, solutionId: string, user: User) {
    const baseUrl = solutionId
      ? `${this.customersAPI}/${customerId}/solutions/${solutionId}/users`
      : `${this.customersAPI}/${customerId}/users`;
    const url = `${baseUrl}/${user.email}`;
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify(user),
    };
    return this.ApiService.performFetch(url, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async removeUser(customerId: string, email: string) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/users/${email}`, fetchOptions);
  }

  static async actOnUserStatus(customerId: string, email: string) {
    const fetchOptions = {
      method: 'POST',
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/users/${email}/status`, fetchOptions);
  }

  static async getClientUserSSO(customerId: string) {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/client-user-sso`);
  }

  static async deleteSAMLSSO(customerId: string) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/saml-client-user-sso`, fetchOptions);
  }

  static async deleteOIDCSSO(customerId: string) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/oidc-client-user-sso`, fetchOptions);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The user cannot be added. Please contact customer support.';
    }
    throw error;
  }
}
